var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(!_vm.edit ? "Lisää uusi kulu" : "Muokkaa kulua")+" ")]),_c('v-card-text',[_c('v-form',{ref:"form"},[(_vm.currentReceipt.receiptType == 'receipt')?_c('v-checkbox',{staticStyle:{"margin-top":"-10px","margin-bottom":"-5px"},attrs:{"label":"Vähennyskelpoinen kulu"},model:{value:(_vm.currentReceipt.taxDeductible),callback:function ($$v) {_vm.$set(_vm.currentReceipt, "taxDeductible", $$v)},expression:"currentReceipt.taxDeductible"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Nimi / Yritys","placeholder":"Esim. K-rauta","dense":"","outlined":"","rules":_vm.validations.required},model:{value:(_vm.currentReceipt.name),callback:function ($$v) {_vm.$set(_vm.currentReceipt, "name", $$v)},expression:"currentReceipt.name"}}),(_vm.currentReceipt.receiptType == 'maintenance')?_c('v-checkbox',{staticStyle:{"margin-top":"-10px","margin-bottom":"-5px"},attrs:{"label":"Toistuva maksu"},model:{value:(_vm.currentReceipt.recurrency.isRecurrent),callback:function ($$v) {_vm.$set(_vm.currentReceipt.recurrency, "isRecurrent", $$v)},expression:"currentReceipt.recurrency.isRecurrent"}}):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],attrs:{"value":_vm.formatDate(_vm.currentReceipt.date),"dense":"","outlined":"","append-icon":"mdi-calendar","label":"Kulun päiväys","rules":_vm.validations.required}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.currentReceipt.date),callback:function ($$v) {_vm.$set(_vm.currentReceipt, "date", $$v)},expression:"currentReceipt.date"}})],1),(
              _vm.currentReceipt.receiptType == 'maintenance' && _vm.currentReceipt.recurrency.isRecurrent
            )?_c('div',[_c('v-text-field',{directives:[{name:"only-numbers",rawName:"v-only-numbers"},{name:"prevent-paste",rawName:"v-prevent-paste"}],attrs:{"dense":"","outlined":"","suffix":"kertaa","label":"Toistetaan"},model:{value:(_vm.currentReceipt.recurrency.repeatsLeft),callback:function ($$v) {_vm.$set(_vm.currentReceipt.recurrency, "repeatsLeft", _vm._n($$v))},expression:"currentReceipt.recurrency.repeatsLeft"}})],1):_vm._e(),_c('v-textarea',{attrs:{"label":"Lisätiedot","placeholder":"Omat merkinnät tai tarkennukset","dense":"","outlined":""},model:{value:(_vm.currentReceipt.description),callback:function ($$v) {_vm.$set(_vm.currentReceipt, "description", $$v)},expression:"currentReceipt.description"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Maksuviite","disabled":_vm.isUpdated && _vm.currentReceipt.outgoingPayments.inUse},model:{value:(_vm.currentReceipt.reference),callback:function ($$v) {_vm.$set(_vm.currentReceipt, "reference", $$v)},expression:"currentReceipt.reference"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path.includes('overview') && _vm.currentReceipt.receiptType === 'receipt'),expression:"$route.path.includes('overview') && currentReceipt.receiptType === 'receipt'"}]},[(_vm.currentUser.currentAccount.settings.subtractReceiptsFromOutgoing)?_c('v-checkbox',{staticStyle:{"margin-top":"-10px","margin-bottom":"-5px"},attrs:{"label":"Vähennetään lähtevistä maksuista"},model:{value:(_vm.currentReceipt.outgoingPayments.inUse),callback:function ($$v) {_vm.$set(_vm.currentReceipt.outgoingPayments, "inUse", $$v)},expression:"currentReceipt.outgoingPayments.inUse"}}):_vm._e(),(
                _vm.currentReceipt.outgoingPayments.inUse &&
                _vm.currentUser.currentAccount.settings.subtractReceiptsFromOutgoing
              )?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],attrs:{"value":_vm.formatDateMonthName(_vm.currentReceipt.outgoingPayments.subtractDate),"dense":"","outlined":"","append-icon":"mdi-calendar","label":"Vähennetään valitun kuun lähtevistä maksuista","rules":_vm.validations.required}},'v-text-field',attrs,false),on))]}}],null,false,752667465),model:{value:(_vm.menuOutgoing),callback:function ($$v) {_vm.menuOutgoing=$$v},expression:"menuOutgoing"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedMonths,"type":"month","first-day-of-week":"1"},on:{"input":function($event){_vm.menuOutgoing = false}},model:{value:(_vm.currentReceipt.outgoingPayments.subtractDate),callback:function ($$v) {_vm.$set(_vm.currentReceipt.outgoingPayments, "subtractDate", $$v)},expression:"currentReceipt.outgoingPayments.subtractDate"}})],1):_vm._e(),(
                _vm.currentReceipt.outgoingPayments.inUse &&
                _vm.currentReceipt.outgoingPayments.paymentId &&
                _vm.currentReceipt.outgoingPayments.subtracted
              )?_c('div',{staticClass:"font-weight-bold success--text"},[_vm._v(" Lisätty toimeksiantajalle lähtevään maksuun. ")]):_vm._e(),(
                _vm.currentReceipt.outgoingPayments.inUse &&
                !_vm.currentReceipt.outgoingPayments.subtracted &&
                _vm.edit
              )?_c('div',{staticClass:"font-weight-bold warning--text"},[_vm._v(" Odottaa lisäystä toimeksiantajalle lähtevään maksuun. ")]):_vm._e(),(
                _vm.currentReceipt.outgoingPayments.inUse &&
                _vm.currentReceipt.outgoingPayments.receiptPaymentId &&
                _vm.edit
              )?_c('div',{staticClass:"font-weight-bold success--text"},[_vm._v(" Lisätty lähtevään kulumaksuun. ")]):_vm._e()],1),_c('div',{staticClass:"mb-2 mt-2",on:{"click":function($event){$event.stopPropagation();_vm.clearProductForm();
              _vm.addProducts = true;
              _vm.productsDialog = true;}}},[_c('label',{staticClass:"mr-1",staticStyle:{"cursor":"pointer"}},[_c('strong',[_vm._v("Lisää tuotteita")])]),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1),_c('div',{staticClass:"mb-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.productListDialog = true}}},[_vm._v(" Tuotteita "),_c('strong',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.currentReceipt.products.length)+" ")]),_vm._v(" kpl ")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Poistu ")]),_c('v-spacer'),(!_vm.edit)?_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.createReceipt}},[_vm._v(" Lisää kulu ")]):_vm._e(),(_vm.edit)?_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.currentReceipt.outgoingPayments.paymentId ||
            _vm.currentReceipt.outgoingPayments.receiptPaymentId
              ? (_vm.confirmDialog = true)
              : _vm.saveReceipt()}}},[_vm._v(" Tallenna kulu ")]):_vm._e()],1)],1)],1),_c('product-form',{attrs:{"addProducts":_vm.addProducts},model:{value:(_vm.productsDialog),callback:function ($$v) {_vm.productsDialog=$$v},expression:"productsDialog"}}),_c('product-list',{attrs:{"edit":_vm.edit},model:{value:(_vm.productListDialog),callback:function ($$v) {_vm.productListDialog=$$v},expression:"productListDialog"}}),_c('confirm-new-payment',{attrs:{"edit":_vm.edit},on:{"savereceipt":_vm.saveReceipt},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }