<template>
  <!-- Dialog receipt -->
  <v-dialog v-model="dialog" width="450">
    <v-card>
      <v-card-title>{{ addProducts ? "Lisää tuote kululle" : "Muokkaa tuotetta" }} </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-autocomplete
            v-if="currentReceipt.receiptType === 'maintenance'"
            label="Valitse tuote (tuotetyyppi)"
            no-data-text="Lisää tuotehallintaan Yhtiövastike, Yhtiölaina tai Vesi"
            :value="currentProduct.productId"
            :items="computedProducts"
            item-text="desc"
            item-value="_id"
            dense
            outlined
            small-chips
            return-object
            clearable
            @click:clear="clearProductId"
            @change="setCurrentProductValues"
          ></v-autocomplete>

          <v-text-field
            v-model="currentProduct.name"
            :rules="validations.required"
            label="Tuotenimi"
            dense
            outlined
          ></v-text-field>

          <v-select
            v-if="$route.params.id && hasLoanProduct"
            v-model="currentProduct.handling"
            label="Yhtiölainan käsittely"
            :items="globalValues.loanHandlingTypes"
            item-text="text"
            item-value="val"
            outlined
            dense
            :rules="validations.required"
          ></v-select>

          <v-text-field
            v-model.number="currentProduct.amount"
            step="0.01"
            type="number"
            :rules="validations.required.concat(validations.positiveNumber)"
            label="Hinta € (sis. alv)"
            dense
            outlined
          />

          <v-text-field
            v-model.number="currentProduct.count"
            min="1"
            type="number"
            step="1"
            dense
            outlined
            label="Määrä"
            :rules="validations.positiveNumber"
          ></v-text-field>

          <v-select
            v-model.number="currentProduct.taxpr"
            :items="globalValues.vatTypes"
            item-value="val"
            item-text="text"
            label="Alv-kanta"
            dense
            outlined
            :rules="validations.zeroOrGreater"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-text v-if="addProducts">
        <v-btn v-if="addProducts" small color="primary" @click="addProduct">Lisää tuote</v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="addProducts" color="primary" @click.stop="dialog = false"> Ok </v-btn>
        <v-btn v-else color="primary" @click="saveProduct">Tallenna tuote</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import validations from "@/validations";
import globalValues from "@/configs/globalValues";
import mixins from "@/mixins/mixins";

export default {
  props: {
    value: { type: Boolean, default: false },
    addProducts: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("receipt", ["currentProduct", "currentReceipt"]),
    ...mapState("product", ["products"]),

    computedProducts() {
      return this.products.filter(
        (el) =>
          el.productType == "Vesi" ||
          el.productType == "Yhtiövastike" ||
          el.productType == "Yhtiölaina"
      );
    },

    hasLoanProduct() {
      const productIds = this.computedProducts.map((el) => {
        if (el.productType === "Yhtiölaina") return String(el._id);
      });
      const currentProductId = this.currentProduct.productId
        ? String(this.currentProduct.productId)
        : null;

      return productIds.includes(currentProductId);
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.getInvoiceProducts();

        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },

  methods: {
    ...mapMutations("receipt", ["setProduct", "addCurrentProductValue"]),
    ...mapActions("product", ["getInvoiceProducts"]),

    addProduct() {
      if (this.$refs.form.validate()) {
        this.currentReceipt.products.push({ ...this.currentProduct });
        this.showPopup("Tuote lisätty kululle", "success");
      }
    },

    saveProduct() {
      if (this.$refs.form.validate()) {
        this.setProduct();
        this.dialog = false;
        this.showPopup("Tuote tallennettu kululle", "success");
      }
    },

    setCurrentProductValues(product) {
      if (product) {
        this.addCurrentProductValue({ field: "name", val: product.desc });
        this.addCurrentProductValue({ field: "productId", val: product._id });
        this.addCurrentProductValue({ field: "taxpr", val: product.taxpr });

        if (!this.hasLoanProduct) {
          this.addCurrentProductValue({ field: "handling", val: null });
        }
      }
    },

    clearProductId() {
      this.addCurrentProductValue({ field: "name", val: "" });
      this.addCurrentProductValue({ field: "handling", val: null });
      this.addCurrentProductValue({ field: "productId", val: null });
    },
  },
};
</script>

<style></style>
