<template>
  <v-container>
    <!-- Form for receipt dates-->
    <v-form ref="form">
      <!-- Search -->
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Etsi nimellä/ostopaikalla tai kuvauksella"
            single-line
            hide-details
            solo
            @keyup.enter="searchReceipts()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" md="3" lg="3" xl="2">
          <v-select
            v-model="receiptType"
            label="Kulun tyyppi"
            :items="globalValues.receiptTypes"
            item-text="text"
            item-value="val"
            dense
            outlined
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="2">
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="startDateFormatted"
                outlined
                dense
                label="Alkaen"
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                append-icon="mdi-calendar"
                ref="startDateFormatted"
                v-prevent-manual-input
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              first-day-of-week="1"
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="2">
          <v-menu
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="endDateFormatted"
                outlined
                dense
                label="Päättyen"
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                append-icon="mdi-calendar"
                v-prevent-manual-input
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              first-day-of-week="1"
              @input="endMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-form>

    <!-- Buttons -->
    <v-row dense>
      <v-col cols="6" md="4">
        <v-btn class="mr-5 mb-2" color="info" @click="searchReceipts()">Etsi</v-btn>
        <v-btn class="mb-2" color="error" outlined @click="resetForm()">Tyhjennä</v-btn>
      </v-col>
    </v-row>

    <!--  Results -->
    <v-row dense>
      <v-col cols="12" sm="6">
        <!-- Total results -->
        <v-card class="resultsBox">
          <v-card-text v-if="showSums" style="padding-bottom: 0; margin-bottom: 20px">
            <h3 class="mb-1">Yhteenveto</h3>
            <p class="mb-1">Kuluja yhteensä: {{ totalLength }} kpl</p>
            <p class="product-wrapper">
              <span>Yhteensä ilman alv:</span>
              <strong>{{
                formatCurrency(sums.total - sums.vat255 - sums.vat24 - sums.vat14 - sums.vat10)
              }}</strong>
            </p>
            <p v-if="sums.vat255" class="product-wrapper">
              <span>Alv 25,5 %:</span>
              <strong>{{ formatCurrency(sums.vat255) }}</strong>
            </p>
            <p v-if="sums.vat24" class="product-wrapper">
              <span>Alv 24 %:</span>
              <strong>{{ formatCurrency(sums.vat24) }}</strong>
            </p>
            <p v-if="sums.vat14" class="product-wrapper">
              <span>Alv 14 %:</span>
              <strong>{{ formatCurrency(sums.vat14) }}</strong>
            </p>
            <p v-if="sums.vat10" class="product-wrapper">
              <span>Alv 10 %:</span>
              <strong>{{ formatCurrency(sums.vat10) }}</strong>
            </p>
            <p class="mt-1 product-wrapper">
              <span>Yhteensä:</span>
              <strong>{{ formatCurrency(sums.total) }}</strong>
            </p>
          </v-card-text>

          <v-card-text v-if="!showSums">
            <p>Hae uudelleen nähdäksesi muuttuneet tulokset...</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validations from "@/validations";
import mixins from "../../mixins/mixins";
import globalValues from "@/configs/globalValues";
import { mapMutations, mapState } from "vuex";

export default {
  mixins: [mixins],

  data() {
    return {
      startMenu: false,
      endMenu: false,
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("receipt", ["totalLength", "sums", "showSums"]),

    search: {
      get() {
        return this.$store.state.receipt.search;
      },
      set(val) {
        this.setFormValue({ field: "search", val });
      },
    },
    receiptType: {
      get() {
        return this.$store.state.receipt.receiptType;
      },
      set(val) {
        this.setFormValue({ field: "receiptType", val });
      },
    },
    startDate: {
      get() {
        return this.$store.state.receipt.startDate;
      },
      set(val) {
        this.setFormValue({ field: "startDate", val });
      },
    },
    endDate: {
      get() {
        return this.$store.state.receipt.endDate;
      },
      set(val) {
        this.setFormValue({ field: "endDate", val });
      },
    },

    startDateFormatted() {
      return this.formatDate(this.$store.state.receipt.startDate);
    },

    endDateFormatted() {
      return this.formatDate(this.$store.state.receipt.endDate);
    },
  },

  methods: {
    ...mapMutations("receipt", ["setFormValue"]),

    resetForm() {
      this.search = "";
      this.startDate = "";
      this.endDate = "";
      this.receiptType = "";
      this.$refs.form.resetValidation();
      this.$emit("searchreceipts");
    },

    searchReceipts() {
      this.$emit("searchreceipts");
    },
  },
};
</script>

<style scoped>
.resultsBox {
  border: 1px solid var(--v-success-base);
  max-width: 280px;
}

.resultsBox p {
  margin-bottom: 2px;
}

.product-wrapper {
  max-width: 220px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
