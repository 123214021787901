<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title> Lisätyt tuotteet </v-card-title>

        <v-card-text class="card-product-list">
          <div>
            <table class="product-table">
              <thead>
                <tr>
                  <th>Nimi</th>
                  <th>Hinta (sis. alv)</th>
                  <th>Määrä</th>
                  <th>Alv.</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prod, idx) in currentReceipt.products" :key="idx">
                  <td>
                    <p>{{ prod.name }}</p>
                  </td>
                  <td>
                    <p>{{ formatCurrency(prod.amount) }}</p>
                  </td>
                  <td>
                    <p>{{ prod.count }} kpl</p>
                  </td>
                  <td>
                    <p>{{ prod.taxpr }} %</p>
                  </td>
                  <td>
                    <div class="product-icons">
                      <v-icon
                        small
                        @click="
                          addProducts = false;
                          editProduct(prod, idx);
                        "
                        >mdi-pencil</v-icon
                      >
                      <v-icon class="pl-1" small @click="deleteProduct(prod, idx)"
                        >mdi-delete</v-icon
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click.stop="dialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Product form -->
    <product-form v-model="productDialog" :addProducts="addProducts"></product-form>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import mixins from "@/mixins/mixins";
import ProductForm from "./ProductForm";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  components: {
    ProductForm,
  },

  data() {
    return {
      productDialog: false,
      addProducts: false,
    };
  },

  mixins: [mixins],

  computed: {
    ...mapState("receipt", ["currentReceipt", "currentProduct"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapMutations("receipt", ["setCurrentProduct", "setCurrentProductIndex"]),

    editProduct(prod, idx) {
      this.setCurrentProduct(prod);
      this.setCurrentProductIndex(idx);
      this.productDialog = true;
    },

    deleteProduct(item, index) {
      let answer = confirm(`Haluatko poistaa tuotteen ${item.name}?`);

      if (answer) {
        this.currentReceipt.products.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 8px;
}

.product-table th,
.product-table td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.product-table th {
  text-align: left;
}

.product-table td {
  width: 20%;
}

.product-table td:first-child {
  width: 30%;
}

.product-table td:last-child {
  text-align: right;
  width: 10%;
}

.product-table td p {
  margin: 0;
}

.product-icons {
  display: flex;
  justify-content: flex-end;
}

.product-icons v-icon {
  margin-left: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .product-table {
    font-size: 10px;
  }
}
</style>
