<template>
  <!-- Confirm create newPayment -->
  <v-dialog v-model="dialog" width="450">
    <v-card>
      <v-card-title> Tallenna kulu </v-card-title>

      <v-card-text v-if="isUpdated">
        <p>
          Yrität muokata kulua, jonka lähtevää maksua on tarkistettu tiliotteelta. Kun tallennat
          kulun, lähtevää maksua muokataan samalla. Tallentaanko?
        </p>
      </v-card-text>

      <v-card-text v-else>
        <p>
          Yrität muokata kulua, johon on liitetty lähteviä maksuja. Kun muokkaat kulua,
          tarkastamattomia lähteviä maksua muokataan samalla.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>

        <!-- Edit -->
        <v-btn v-if="edit" color="info" @click="$emit('savereceipt')"> Tallenna kulu </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters("receipt", ["isUpdated"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
