var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.edit = false;
        _vm.receiptDialog = true;}}},[_vm._v(" Uusi kulu ")])],1),_c('search-receipts',{on:{"searchreceipts":_vm.getDataFromApi}}),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.receipts,"options":_vm.options,"show-expand":"","footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"server-items-length":_vm.totalLength,"loading":_vm.loading,"item-key":"_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"dense":"","headers":_vm.productHeaders,"items":item.products,"items-per-page":100,"footer-props":_vm.customFooterProps},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.amount)))])]}},{key:"item.taxpr",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.taxpr)+" %")])]}},{key:"item.totalVat",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(_vm.calcTaxTotal(item))))])]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.count * item.amount)))])]}}],null,true)})],1)]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.receiptType",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getReceiptType(item.receiptType)))])]}},{key:"item.recurrency.isRecurrent",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatBoolean(item.recurrency.isRecurrent)))])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.countTotalPrice(item.products))))])]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.taxDeductible",fn:function(ref){
        var item = ref.item;
return [(item.taxDeductible)?_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(_vm.formatBoolean(item.taxDeductible)))]):_vm._e(),(!item.taxDeductible)?_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v(_vm._s(_vm.formatBoolean(item.taxDeductible)))]):_vm._e()]}},{key:"item.outgoingPayments",fn:function(ref){
        var item = ref.item;
return [(item.outgoingPayments.inUse)?_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v("Käytössä")]):_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v("Ei käytössä")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.formatReceipt(item);
            _vm.edit = true;
            _vm.receiptDialog = true;}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]),_c('v-icon',{class:{ 'info--text': _vm.hasItems(item.files) },attrs:{"title":"Lataa tosite","small":""},on:{"click":function($event){_vm.receiptId = item._id;
            _vm.showFileDialog = true;}}},[_vm._v("mdi-paperclip")])],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei kuluja")])]},proxy:true}],null,true)}),_c('receipt-dialog',{attrs:{"edit":_vm.edit},on:{"savereceipt":_vm.saveReceipt,"createreceipt":_vm.create},model:{value:(_vm.receiptDialog),callback:function ($$v) {_vm.receiptDialog=$$v},expression:"receiptDialog"}}),_c('File',{attrs:{"document-id":_vm.receiptId,"title":"Lataa tosite","document":"receipt","bucket":"vuokranet-tositteet","model":"receipt"},on:{"sendnewfiles":_vm.replaceFilesArray},model:{value:(_vm.showFileDialog),callback:function ($$v) {_vm.showFileDialog=$$v},expression:"showFileDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }